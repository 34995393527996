<script setup lang="ts">
  import { config, isDemo } from '@/bootstrap/config'
  import Logo from '@/common/components/svg/Logo.vue'
  import { AuthUserQuery } from '@/common/graphql/types'
  import { logError } from '@/common/utils'
  import { useStore } from '@/store'
  import { useQuery } from '@vue/apollo-composable'
  import { computed, onMounted, reactive, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import QueryAuthUser from './graphql/QueryAuthUser.gql'
  import { useAppDataLoader } from '@/common/services/appdata/AppData.api'
  import Callout from '@/common/components/callout/Callout.vue'
  import { loadLocale } from '@/bootstrap/i18n'

  const store = useStore()
  const i18n = useI18n()
  const version = config.version
  const router = useRouter()
  const demo = isDemo()
  const { fetch: fetchAppData } = useAppDataLoader(store)

  const state = reactive({
    username: demo ? 'admin' : '',
    password: demo ? 'admin' : '',
    errors: {} as Record<string, any[]>,
    loading: false,
  })

  onMounted(() => {
    if (demo) {
      submit()
    }
  })

  watch(() => store.state.app.setupTime, newV => {
    if (!newV && router.currentRoute.value.name !== 'setup') {
      router.push({ name: 'setup' })
    }
  })

  const errorText = computed(() => {
    if (!state.errors.hasOwnProperty('common')) {
      return ''
    }

    return i18n.t(state.errors.common[0].message, state.errors.common[0].data)
  })

  async function onSuccess (user: AuthUserQuery['authUser']) {
    if (!user) {
      return
    }

    store.commit('user/set', user)

    await loadLocale(user.locale)

    await fetchAppData(true)

    state.errors = {}

    if (router.currentRoute.value.query.redirect) {
      await router.push(router.currentRoute.value.query.redirect as string)
    } else {
      await router.push({ name: 'homepage' })
    }
  }

  const { onResult } = useQuery<AuthUserQuery>(QueryAuthUser, {}, { fetchPolicy: 'no-cache' })
  onResult(result => {
    if (!result || result.loading) {
      return
    }

    if (result?.data?.authUser) {
      onSuccess(result.data.authUser)
    }
  })

  async function submit () {
    state.loading = true
    try {
      const response = await fetch(`${config.backend}/login`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          username: state.username,
          password: state.password,
        }),
      })
      const json = await response.json()
      if (json.ok) {
        await onSuccess(json.user)
      } else {
        state.errors = json.errors
      }
    } catch (error: any) {
      logError(error, state)
      state.errors = { username: [i18n.t(error.toString())] }
    }
    state.loading = false
  }
</script>

<template>
  <div>
    <form autocomplete="off" class="home">
      <div class="flex flex-col justify-center items-center">
        <Logo class="w-64" />
      </div>
      <div class="mt-20 shadow-lg rounded max-w-lg p-6 bg-white mx-auto">
        <FormInput
          id="username"
          v-model="state.username"
          v-focus
          :errors="state.errors"
          label="user.fields.name"
          name="username"
          autocomplete="off"
          @keydown.enter="submit"
        />
        <FormInput
          id="password"
          v-model="state.password"
          :errors="state.errors"
          label="user.fields.password"
          name="password"
          type="password"
          autocomplete="off"
          @keydown.enter="submit"
        />
        <Callout v-if="errorText" type="warning" class="mt-4" :text="errorText" />
        <ActionButton
          id="login"
          type="primary"
          :loading="state.loading"
          class="text-base py-3 w-full mt-6 uppercase"
          @click="submit"
        >
          {{ $t('user.actions.login') }}
        </ActionButton>
      </div>
    </form>
    <div class="fixed bottom-0 w-full pb-4 text-center text-xs text-gray-400">
      {{ version }}
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .home
    max-width calc(100% - 40px)
    width 400px
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -70%)

    @media screen and (max-height: 800px)
      transform translate(-50%, -50%)
</style>
